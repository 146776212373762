<template>
  <div class="buy-container">
    <div class="title">
      {{
        bought
          ? $t('product').SuccessfulPurchase
          : $t('product').ConfirmPurchase
      }}
    </div>
    <div v-if="!bought">
      <div class="amount">
        <span>{{ $t('product').Amount }}</span>
        <van-field
          v-model="amount"
          @input="amount = amount.replace(/[^\d]/g, '')"
        />
        <!-- <button style="width: 50px;" @click="handleCreateOrder">全部下单 </button> -->
        <div class="btnall"  @click="AllAmount()">
        {{ $t('product').ConfirmAllin }}
        </div>
      </div>
      <div class="select-amount">
        <div v-for="item in amountList" :key="item" @click="selectAmount(item)">
          {{ basicConfig.Symbol }}{{ item }}
        </div>
      </div>
      <div class="line">
        <span>{{ $t('product').Buy }}：</span>
        <span>{{ basicConfig.Symbol }}{{ amount ? amount : 0 }}</span>
      </div>
      <div class="line">
        <span>{{ $t('product').ExpectedIncome }}：</span>
        <span>{{ basicConfig.Symbol }}{{ income ? income : 0 }} </span>
      </div>
      <div class="line">
        <span>{{ $t('product').assetType }}：</span>
        <span>{{ showProductName(buyProduct.Id) }}</span>
      </div>
      <div class="line">
        <span>{{ $t('product').BillingCycle }}：</span>
        <span>{{ allTime / 60 }} {{ $t('product').Min }}</span>
      </div>
      <div class="line">
        <span>{{ $t('product').OrderDirection }}：</span>
        <span class="down" :class="{ up: isRise === true }">
          <!-- {{
          isRise === true ? $t('product').Increase : $t('product').Decrease
        }} -->
          {{ showDirection(isRise) }}
        </span>
      </div>
      <div class="line">
        <span>{{ $t('product').MinimumBalance }}：</span>
        <span>{{ basicConfig.Symbol }}{{ buyProduct.BalanceLow }} </span>
      </div>
      <div class="line">
        <span>{{ $t('product').MinimumPurchase }}：</span>
        <span>{{
          buyProduct.BuyLow === -1 ? $t('product').unlimited : buyProduct.BuyLow
        }}</span>
      </div>
      <div class="line">
        <span>{{ $t('product').upToBuy }}：</span>
        <span>{{
          buyProduct.BuyTop === -1 ? $t('product').unlimited : buyProduct.BuyTop
        }}</span>
      </div>
      <div class="line">
        <span>{{ $t('product').CurrentPrice }}：</span>
        <span>{{ nowPrice.Price }}</span>
      </div>
      <div class="btn" @click="handleCreateOrder">
        {{ $t('product').Confirm }}
      </div>
    </div>
    <div v-else class="bought-box">
      <van-circle
        v-model="currentRate"
        :color="gradientColor"
        layer-color="#333333"
        size="110px"
        :text="text"
      />
      <!-- <p>
        <span class="laber yellow">{{ $t('product').product }}：</span>
        <span>{{ showProductName(orderInfo.ProductId) }}</span>
      </p> -->
      <p>
        <span class="laber yellow">{{ $t('product').BuyPrice }}：</span>
        <span>{{ orderInfo.StartInvestment }}</span>
      </p>
      <p v-if="!basicConfig.IsHideLastPrice">
        <span class="laber yellow">{{ $t('product').EndPrice }}：</span>
        <span v-if="!orderInfo.EndInvestment">--/--</span>
        <span v-else>{{ orderInfo.EndInvestment }} </span>
      </p>
      <p>
        <span class="laber">{{ $t('product').CurrentPrice }}：</span>
        <span class="down" :class="{ up: nowPrice.Rise }"
          >{{ nowPrice.Price }}
          <van-icon name="down" :class="{ upMode: nowPrice.Rise }" />
        </span>
      </p>
      <p>
        <span class="laber">{{ $t('product').OrderDirection }}：</span>
        <span class="down" :class="{ up: orderInfo.Direction === 'Rise' }">
          <!-- {{
          orderInfo.Direction === 'Rise'
            ? $t('product').Increase
            : $t('product').Decrease
        }} -->
          {{ showDirection(orderInfo.Direction === 'Rise') }}
        </span>
      </p>
      <p>
        <span class="laber">{{ $t('product').BillingCycle }}：</span>
        <span>{{ orderInfo.Time }} {{ $t('product').second }}</span>
      </p>
      <p>
        <span class="laber">{{ $t('product').InvestmentAmount }}：</span>
        <span>{{ basicConfig.Symbol }}{{ orderInfo.Amount }} </span>
      </p>
      <p>
        <span class="laber">{{ $t('product').profit }}：</span>
        <span v-if="!orderInfo.lastMoney">--/--</span>
        <span v-else>{{ basicConfig.Symbol }}{{ orderInfo.lastMoney }} </span>
      </p>
      <div class="btn" v-show="showBtn" @click="handleSubmit">
        {{ $t('product').Confirm }}
      </div>
    </div>
  </div>
</template>

<script>
import productApi from '@/api/product'
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import userApi from '@/api/user'
const defaultgradientColor = {
  '100%': '#C09E5A',
  '75%': '#B18A41',
  '50%': '#D1B577',
  '25%': '#E7D39D',
}
const defaultZerogradientColor = {
  '100%': '#333333',
  '0%': '#333333',
}
export default {
  props: {
    isRise: {
      type: Boolean,
      default: true,
    },
    buyProduct: {
      type: Object,
      default() {
        return {}
      },
    },
    lastOrderInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    buyTimeMode: {
      type: Number,
      default: 0,
    },
    productNowInfo: {
      type: Array,
      default() {
        return []
      },
    },
    productList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      bought: false,
      currentRate: 0,
      // amountList: [5000, 2000, 1000, 500, 200, 100],
      text: '',
      showBtn: false,
      income: null,
      amount: null,
      direction: 'up',
      allTime: null,
      rate: null,
      buyTimer: null,
      orderInfo: {},
      nowPrice: {
        Price: 0,
        Rise: true,
      },
      gradientColor: defaultgradientColor,
    }
  },
  watch: {
    currentRate(newValue) {
      this.gradientColor = newValue
        ? defaultgradientColor
        : defaultZerogradientColor
    },
    amount(newValue) {
      this.income = Math.floor(newValue * this.rate)
    },
    buyProduct() {
      this.getData()
      this.selectAmount()
    },
    buyTimeMode() {
      this.getData()
      this.selectAmount()
    },
    isRise() {
      this.getData()
      this.selectAmount()
    },
    lastOrderInfo(newValue) {
      this.orderInfo = newValue
      this.handleOrderInfo()
    },
    productNowInfo(newValue) {
      newValue.forEach((item) => {
        if (this.bought) {
          if (item.ProductId === this.orderInfo.ProductId) {
            this.nowPrice = item
          }
        } else {
          if (item.ProductId === this.buyProduct.Id) {
            this.nowPrice = item
          }
        }
      })
    },
  },
  computed: {
    ...mapGetters(['lang', 'basicConfig']),
    amountList() {
      return this.basicConfig.BuyAmounts
        ? this.basicConfig.BuyAmounts.split(',')
        : []
    },
  },
  methods: {
    showDirection(Direction) {
      let basicConfig = this.basicConfig
      let lang = this.$store.getters.lang
      if (lang === 'zh') {
        return Direction ? basicConfig.RiseName : basicConfig.FallName
      } else if (lang === 'en') {
        return Direction
          ? basicConfig.RiseNameEnglish
          : basicConfig.FallNameEnglish
      } else if (lang === 'ft') {
        return Direction
          ? basicConfig.RiseNameTraditional
          : basicConfig.FallNameTraditional
      } else {
        return Direction
          ? basicConfig.RiseNameJapanese
          : basicConfig.FallNameJapanese
      }
    },
    handleSubmit() {
      this.$emit('submit')
      setTimeout(() => {
        this.showBtn = false
        this.orderInfo = {}
        this.bought = false
        this.getData()
        this.selectAmount()
      }, 310)
    },
    async AllAmount()
    {
      let res = await userApi.userInfo()
      this.amount =Math.trunc(res.Balance)// Number(res.Balance)    
      this.income = Math.floor(res.Balance * this.rate)
    },
    async handleCreateOrder() {
      let obj = {
        ProductId: this.buyProduct.Id,
        Direction: this.isRise ? 'Rise' : 'Fall',
        TimeId: this.timeId,
        StartInvestment: this.nowPrice.Price,
        Amount: Number(this.amount),
      }
      if (obj.Amount === 0) {
        this.$toast({
          position: 'bottom',
          message: this.$t('profit').placeholder,
        })
        return
      }
      await productApi.createOrder(obj)
      this.amount = null
      this.$emit('getInfo')
      this.$toast.success(this.$t('product').SuccessfulPurchase)
      this.getLastOrder(true)
    },

    selectAmount(amount) {
      this.amount = Number(amount)
      this.income = Math.floor(amount * this.rate)
    },
    showProductName(ProductId) {
      let name = ''
      this.productList.forEach((item) => {
        if (item.Id === ProductId) {
          if (this.lang === 'zh') {
            name = item.Name
          } else if (this.lang === 'en') {
            name = item.EnglishName
          } else {
            name = item.TraditionalName
          }
          return
        }
      })
      return name
    },
    getData() {
      if (JSON.stringify(this.buyProduct) === '{}') {
        return
      }
      let Proportion = this.buyProduct.Proportion[this.buyTimeMode]
      this.rate = Proportion.Win + 1
      this.allTime = Proportion.Time
      this.timeId = Proportion.Id
    },
    handleOrderInfo() {
      let orderInfo = this.orderInfo
      if (JSON.stringify(orderInfo) === '{}') {
        return
      }
      if (this.buyTimer) {
        clearInterval(this.buyTimer)
        this.buyTimer = null
      }
      if (!orderInfo.CreateTime) {
        return
      }
      // ios 时间
      // let time =
      //   new Date(orderInfo.EndTime.replace(/-/g, '/')).getTime() -
      //   new Date().getTime()

      if (orderInfo.SurplusTime > 0) {
        this.bought = true
        let RemainingTime = orderInfo.SurplusTime
        this.text = this.$t('product').PleaseWait + ' ' + RemainingTime + 's'
        this.currentRate = (RemainingTime / orderInfo.Time) * 100
        this.buyTimer = setInterval(() => {
          RemainingTime--
          if (RemainingTime > 0) {
            this.text =
              this.$t('product').PleaseWait + ' ' + RemainingTime + 's'
            this.currentRate = (RemainingTime / orderInfo.Time) * 100
          } else {
            this.text = this.$t('product').InLiquidation
            this.currentRate = (RemainingTime / orderInfo.Time) * 100
            clearInterval(this.buyTimer)
            this.buyTimer = null
            setTimeout(() => {
              this.getLastOrder(false)
            }, 1000)
          }
        }, 1000)
      } else {
        this.bought = false
        this.showBtn = false
        this.amount = null
        this.income = null
      }
    },
    async getLastOrder(createMode) {
      this.orderInfo = await productApi.lastOrder()
      if (createMode) {
        this.handleOrderInfo(this.orderInfo)
      } else {
        if (!this.orderInfo.IsDraw) {
          setTimeout(() => {
            this.getLastOrder()
          }, 1000)
          return false
        }
        clearInterval(this.buyTimer)
        this.buyTimer = null
        this.currentRate = 0
        const lastMoney = this.orderInfo.EndAmount - this.orderInfo.Amount
        this.orderInfo.lastMoney = lastMoney
        if (lastMoney > 0) {
          this.text = this.$t('product').profitText
          this.$toast(
            this.$t('product').success + this.basicConfig.Symbol + lastMoney
          )
        } else {
          this.text = this.$t('product').lossText
          this.$toast(
            this.$t('product').lossText +
              this.basicConfig.Symbol +
              Math.abs(lastMoney)
          )
        }

        this.$emit('getInfo')
        this.showBtn = true
      }
    },
    clearOrderInfo() {
      if (this.buyTimer) {
        clearInterval(this.buyTimer)
        this.buyTimer = null
      }
      this.bought = false
      this.orderInfo = {}
    },
  },
  destroyed() {
    clearInterval(this.buyTimer)
    this.buyTimer = null
  },
  created() {
    this.getData()
    this.orderInfo = this.lastOrderInfo
    this.handleOrderInfo()
    EventBus.$on('OrderSettle', () => {
      this.getLastOrder()
    })
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.buy-container
  background: #19191b;
  color: #ebe5cd;
  display  flex
  flex-direction column
  font-size 14px
  height 485px
  .title
    height 50px
    font-size 16px
    line-height 50px
    text-align center
  .amount
    flex-center()
    height 25px
    padding 20px 10% 10px
    span
      display inline-block
      text-align left
      width 90px
    .van-field
      padding 0 10px
      color: #fff;
      border-bottom 1px solid  #E0C58A
      background none
      >>> .van-field__control
        color #FFF
  .select-amount
    padding 10px
    display flex
    flex-wrap wrap
    justify-content center
    div
      margin 5px
      width 28vw
      height 40px
      flex-center()
      border-radius 5px
      border 2px solid #ebe5cd
      font-weight bold
  .line
    height 24px
    display flex
    align-items center
    justify-content space-between
    padding 0 20px
    width 100%
    span
      color #838383
    span:nth-of-type(2)
      color #ebe5cd
    span.down
      color #31ad91
  .btn
    background: linear-gradient(315deg,#e8d49e,#d6b372)!important;
    height 44px
    color #222
    flex-center()
    border-radius 5px
    width 95vw
    margin 10px auto
  .btnall
    background: linear-gradient(315deg,#e8d49e,#d6b372)!important;
    height 0.25rem
    color #222
    flex-center()
    border-radius 5px
    width 60vw
    margin 10px auto
  .bought-box
    display flex
    flex-direction column
    justify-content center
    align-items center
    .van-circle
      margin 30px
      >>> .van-circle__text
        color #fff
    p
      height 25px
      display flex
      line-height 25px
      width 100%
      .van-icon
        position relative
        top 3px
        margin-left 3px
      span
        flex 1

      .laber
        text-align right
        font-size 14px
        color #838383
      .yellow
        color #ddbe36
      .upMode
        transform rotateX(180deg) !important
</style>
